$(document).ready(function() {
  // handle click and add class
  // $("#generate").on("click", function() {
  //   var text = getDeveloperName() + "-" + $("#name").val();
  //   $("#output").text(text);
  //   setClipboardText(text);
  // });

  // Focus on name field on page load #16
  $("#name").focus();

  $("#name").on("input", function(e) {
    // $("#generate").click();
    if($('#name').val().length >= 1)
      generateText();
    else
      $("#output").text("Write / paste jira id in 👆 the above input box.");
    
    $("#name").focus();
  });

  //Handle on Paste

  $("#name").bind("paste", function(e) {
    setTimeout(function() {
      $("#generate").click();
    }, 100);

  });

  // Custom Developer Name | Submit Button
  $("#changeDeveloperName").click(function(e) {
    e.preventDefault();
    var name = $("#developerName").val();
    setDeveloperName(name);
    // Close Modal
    $.modal.close();
    regenerateForm();
  });

  // Handle Restore Developer Name
  $("#retoreDeveloperName").click(function(e) {
    e.preventDefault();
    clearLocalStorage();

    // Clear Input Field
    $("#developerName").val("");

    // Close Modal
    $.modal.close();
    regenerateForm();
  });

  // Themes on load
  loadTheme();
});

function setClipboardText(text) {
  var id = "mycustom-clipboard-textarea-hidden-id";
  var existsTextarea = document.getElementById(id);

  if (!existsTextarea) {
    console.log("Creating textarea");
    var textarea = document.createElement("textarea");
    textarea.id = id;
    // Place in top-left corner of screen regardless of scroll position.
    textarea.style.position = "fixed";
    textarea.style.top = 0;
    textarea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textarea.style.width = "1px";
    textarea.style.height = "1px";

    // We don't need padding, reducing the size if it does flash render.
    textarea.style.padding = 0;

    // Clean up any borders.
    textarea.style.border = "none";
    textarea.style.outline = "none";
    textarea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textarea.style.background = "transparent";
    document.querySelector("body").appendChild(textarea);
    console.log("The textarea now exists :)");
    existsTextarea = document.getElementById(id);
  } else {
    console.log("The textarea already exists :3");
  }

  existsTextarea.value = text;
  existsTextarea.select();

  try {
    var status = document.execCommand("copy");
    if (!status) {
      console.error("Cannot copy text");
    } else {
      console.log("The text is now on the clipboard");
    }
  } catch (err) {
    console.log("Unable to copy.");
  }
}

/**
 * Get Developer Name
 */
function getDeveloperName() {
  var text = "Dev-amitkhanchandani-gep";
  if (localStorage.getItem("devName") != null) {
    text = localStorage.getItem("devName");
  }
  return text;
}

/**
 * Set Custom Developer Name.
 * @param {string} name Name of the Developer
 */
function setDeveloperName(name) {
  localStorage.setItem("devName", name);
}

/**
 * Clear localstorage
 */
function clearLocalStorage() {
  localStorage.clear();
}

/**
 * Regenerate Form
 *
 * if name is not empty
 */
function regenerateForm() {
  if ($("#name").val() != "") {
    generateText();
  }
}

function loadTheme() {
  // check localstorage
  var theme = "theme-dark-blue";
  if (localStorage.getItem("theme") != null) {
    theme = localStorage.getItem("theme");
  }
  resetTheme();
  // Setup theme
  $("#page").addClass(theme);
}

function resetTheme() {
  $("#page").removeClass("theme-dark-blue");
  $("#page").removeClass("theme-smart-material");
  $("#page").removeClass("theme-clean-white");
}

function generateText() {
  var text = getDeveloperName() + "-" + $("#name").val();
  $("#output").text(text);
  setClipboardText(text);
  setTimeout(() => {
    showToastMessage(text);
  }, 3000);
}

function showToastMessage(text) {
  // flash.setting.method = toastmessage;
  // var toastmessage = function(message, options) {
  //   flash.formatOptions(options);
  //   options.text = message;
    
  //   options.stayTime = options.time;
  //   options.type = typeMap[options.type] || options.type;
  //   $().toastmessage("showToast", options);
  // };

  flash(text, {
    type: $("#type").val(),
    sticky: false,
    closable: true
  });
  // toastmessage(text)
}

// if(location.host === "gep-branch.amitk.co.in"){
//   document.getElementById('domain-notice').style.display = "block";
// }